import { render } from "./AboutRaise.vue?vue&type=template&id=64a4bdc2"
import script from "./AboutRaise.ts?vue&type=script&lang=ts"
export * from "./AboutRaise.ts?vue&type=script&lang=ts"

import "./aboutRaise.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "64a4bdc2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('64a4bdc2', script)) {
    api.reload('64a4bdc2', script)
  }
  
  module.hot.accept("./AboutRaise.vue?vue&type=template&id=64a4bdc2", () => {
    api.rerender('64a4bdc2', render)
  })

}

script.__file = "src/views/Catalog/components/RaiseProfile/components/AboutRaise/AboutRaise.vue"

export default script
import { render } from "./BonusState.vue?vue&type=template&id=50d8ab66"
import script from "./BonusState.ts?vue&type=script&lang=ts"
export * from "./BonusState.ts?vue&type=script&lang=ts"

import "./bonusState.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "50d8ab66"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('50d8ab66', script)) {
    api.reload('50d8ab66', script)
  }
  
  module.hot.accept("./BonusState.vue?vue&type=template&id=50d8ab66", () => {
    api.rerender('50d8ab66', render)
  })

}

script.__file = "src/views/Catalog/components/BonusState/BonusState.vue"

export default script
import { render } from "./RaiseProfile.vue?vue&type=template&id=294552f4"
import script from "./RaiseProfile.ts?vue&type=script&lang=ts"
export * from "./RaiseProfile.ts?vue&type=script&lang=ts"

import "./raiseProfile.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "294552f4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('294552f4', script)) {
    api.reload('294552f4', script)
  }
  
  module.hot.accept("./RaiseProfile.vue?vue&type=template&id=294552f4", () => {
    api.rerender('294552f4', render)
  })

}

script.__file = "src/views/Catalog/components/RaiseProfile/RaiseProfile.vue"

export default script